import React from 'react';
import { throttle } from 'throttle-debounce';

import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';

import type { CarouselApi, CarouselBreakpointOptions, CarouselProps } from './index';

type Options = Pick<CarouselProps, 'breakpoints' | 'slidesPerView' | 'slidesPerGroup' | 'spaceBetween'>;

const THROTTLE_DELAY = 100;

export function useCarouselOptions(
  initialOptions: Options,
  carouselApi: CarouselApi | undefined
): CarouselBreakpointOptions {

  const {
    slidesPerView,
    slidesPerGroup = slidesPerView,
    spaceBetween,
    breakpoints
  } = initialOptions;

  const [options, setOptions] = React.useState<CarouselBreakpointOptions>(() => ({
    slidesPerView,
    slidesPerGroup,
    spaceBetween
  }));

  React.useEffect(() => {

    // If no breakpoints are defined, just apply the initial options
    if (!breakpoints || Object.keys(breakpoints).length === 0) {
      setOptions({ slidesPerView, slidesPerGroup, spaceBetween });
      return;
    }

    const updateOptions = throttle(THROTTLE_DELAY, () => {
      const newOptions = computeOptions({
        slidesPerView, slidesPerGroup, spaceBetween, breakpoints
      });
      setOptions(newOptions);

      if (carouselApi) {
        carouselApi.reInit({ slidesToScroll: newOptions.slidesPerGroup });
      }
    });

    // Apply options on initial mount
    updateOptions();

    window.addEventListener('resize', updateOptions);

    return () => {
      window.removeEventListener('resize', updateOptions);
    };
  }, [breakpoints, slidesPerView, slidesPerGroup, spaceBetween, carouselApi]);

  // Return memoized computed options
  const computedOptions = React.useMemo(() => ({
    slidesPerView: options.slidesPerView,
    slidesPerGroup: options.slidesPerGroup,
    spaceBetween: options.spaceBetween,
  }), [options.slidesPerView, options.slidesPerGroup, options.spaceBetween]);
  return computedOptions;
}

// Computes the options based on the breakpoints
function computeOptions(options: Options): CarouselBreakpointOptions {
  const {
    breakpoints = {},
    slidesPerView = 1,
    slidesPerGroup = 1,
    spaceBetween = 8
  } = options;

  // Sort breakpoints to ensure largest values are applied last
  const sortedBreakpoints = Object.keys(breakpoints).map(Number).sort((a, b) => a - b);
  let newOptions = {
    slidesPerView,
    slidesPerGroup: slidesPerGroup || slidesPerView,
    spaceBetween
  };

  sortedBreakpoints.forEach((breakpoint) => {
    const { matches } = window.matchMedia(`(min-width: ${breakpoint}px)`);
    if (matches) {
      const {
        slidesPerView: breakpointSlidesPerView,
        slidesPerGroup: breakpointSlidesPerGroup = breakpointSlidesPerView,
        spaceBetween: breakpointSpaceBetween
      } = breakpoints[breakpoint];
      newOptions = {
        ...newOptions,
        ...(breakpointSlidesPerView ? { slidesPerView: breakpointSlidesPerView } : {}),
        ...(breakpointSlidesPerGroup ? { slidesPerGroup: breakpointSlidesPerGroup } : {}),
        ...(breakpointSpaceBetween ? { spaceBetween: breakpointSpaceBetween } : {}),
      };
    }
  });

  return newOptions;
}
